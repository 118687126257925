import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal"
  }, [_c('wrapper', {
    scopedSlots: _vm._u([{
      key: "alert",
      fn: function () {
        return [_vm._v(" Истек срок действия подписки ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Для доступа к мониторингу позиций требуется подписка ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', [_vm._v(" Не волнуйтесь, мы продолжаем сбор данных по вашим проектам. Для продолжения работы с мониторингом продлите подписку. ")]), _vm.$store.state.user.user.parent ? _c('div', {
          staticClass: "mt24"
        }, [_vm._v(" Для этого обратитесь к главному аккаунту - "), _c('span', {
          staticClass: "parent-email"
        }, [_vm._v(_vm._s(_vm.$store.state.user.user.parent.parent.user_email_address))])]) : _c('div', {
          staticClass: "mt24"
        }, [_vm._v(" Сделать этого можно в разделе “Тариф и оплата” "), _c('div', {
          staticClass: "mt24"
        }, [_c('UiButton', {
          attrs: {
            "variant": "blue"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push('/profile/plan');
            }
          }
        }, [_vm._v(" Перейти в личный кабинет ")])], 1)])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };